import Swiper from "swiper";
import {Autoplay} from "swiper/modules";
import 'swiper/swiper.css';

const swiper = new Swiper(".mySwiper", {
    modules: [Autoplay],
    loop: true,
    autoplay: {
        delay: 15000,
        disableOnInteraction: false,
    }
});
